import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { APPLE_STORE_URL, GOOGLE_STORE_URL } from '@dougs/core/responsive';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-download-mobile-app-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
  ],
  templateUrl: './download-mobile-app-modal.component.html',
  styleUrl: './download-mobile-app-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadMobileAppModalComponent {
  public readonly APPLE_STORE_URL = APPLE_STORE_URL;
  public readonly GOOGLE_STORE_URL = GOOGLE_STORE_URL;
}
