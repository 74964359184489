<div dougsModalTitle>
  <div></div>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent class="large no-padding download-mobile">
  <div class="download-mobile__left px-40">
    <div class="download-mobile__left__header">
      <h3 class="mb-16">Téléchargez l'<span class="color-primary">application Dougs</span></h3>
      <p class="small color-primary-700">
        Gérez vos finances simplement, où que vous soyez, avec l’ajout rapide de vos notes de frais.
      </p>
    </div>
    <div class="download-mobile__qr-code p-8">
      <img src="images/illustrations/mobile-qr-code.svg" alt="qr code" />
    </div>
    <div class="download-mobile__links">
      <a [href]="GOOGLE_STORE_URL" target="_blank" class="download-mobile__links__button pointer mr-8">
        <img [src]="'images/illustrations/google-play-light.svg'" alt="google play store" />
      </a>
      <a [href]="APPLE_STORE_URL" target="_blank" class="download-mobile__links__button pointer">
        <img [src]="'images/illustrations/apple-store-light.svg'" alt="apple store" />
      </a>
    </div>
  </div>
  <div class="download-mobile__right">
    <img class="pb-16" src="images/onboarding/download-mobile.png" alt="Download mobile" width="305" />
  </div>
</div>
<div dougsModalFooter>
  <div class="buttons">
    <dougs-button color="secondary" dougsModalClose> Annuler </dougs-button>
    <dougs-button color="primary" [dougsModalClose]="true">
      <i class="fal fa-check mr-8"></i>
      C'est téléchargé !
    </dougs-button>
  </div>
</div>
